<template>
  <div class="main-card mb-3 card pa-0 ma-0" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{$t('erp.lang_orderedItemsOverview')}} {{ this.order != null ? '(' + this.order.reference + ')' : "" }}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">


          <v-btn :disabled="this.loadSave" :loading="this.loadSave" color="warning" icon @click="openNote">
            <v-icon>note</v-icon>
          </v-btn>

          <v-btn :disabled="this.loadSave" :loading="this.loadSave" color="primary" icon @click="save">
            <v-icon>save</v-icon>
          </v-btn>
        </div>
      </div>
      <v-card-text class="pa-0 ma-0">
        <b-form-input v-model="search" :min="0" class="ma-0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" placeholder="Search"/>
        <v-data-table :headers="this.headers" :items="this.items" :loading="this.loading" :search.sync="search">
          <template v-slot:item.ean="{item}">
            <strong>{{ item.ean }}</strong>
          </template>
          <template v-slot:item.wareName="{item}">
            <strong class="text-primary">{{ item.wareName }}</strong>
          </template>
          <template v-slot:item.currentQTY="{item}">
            <v-chip :color="getColor(item.currentQTY , item.minBestand , item.currentQTY)"
                    class="mr-2"
                    small>
              {{ item.currentQTY }}
            </v-chip>
          </template>

          <template v-slot:item.orderedQTY="{item}">
            <strong class="text-primary">{{$t('erp.lang_current')}}:</strong> {{ item.unitPerPackage * item.packagesRecieved }}
            <br/>
            <strong class="text-success">{{$t('erp.lang_received')}}:</strong> {{ item.unitPerPackage * item.real_packagesRecieved }}

            <br/>
            <strong class="text-danger">{{$t('erp.lang_rest')}}:</strong>
            {{ item.sollBestand - (item.unitPerPackage * item.real_packagesRecieved) }}
          </template>

          <template v-slot:item.actions="{item}">
            <v-btn v-if="(item.chargeNoRequired || item.mhdRequired) || (item.chargeNoRequired && item.mhdRequired)"
                   color="primary" icon
                   @click="scan(item)">
              <v-icon>
                {{ $t('generic.lang_edit') }}
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.packagesRecieved="{item}">
            <!--<strong>
              {{/*
                (Number(item.orderedQTY / item.unitPerPackage) &&
                    (item.orderedQTY / item.unitPerPackage % 1
                        === 0)) ? item.orderedQTY / item.unitPerPackage :
                    ((item.orderedQTY / item.unitPerPackage == 0) ? 0
                        : parseInt(item.orderedQTY / item.unitPerPackage) + 1)*/
              }}
            </strong>-->
            <b-form-input v-model="item.packagesRecieved" type="number"/>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text v-if="hasChargenNo">
        <v-row justify="center">
          <v-dialog v-model="hasChargenNo" max-width="500" persistent>
            <!--if hasChargenNo dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form ref="hasChargenNo" lazy-validation onsubmit="return false">
                  <v-row>
                    <v-col class="" cols="12">
                      <v-text-field v-model="editedItem.batchNo" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :rules="[v => !!v]" autofocus dense
                                    :label="$t('erp.lang_enterChargeNo')"
                                    outlined @focus="showTouchKeyboard"
                                    @keypress.enter="close"/>
                    </v-col>

                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t('generic.lang_cancel') }}
                      </v-btn>
                      <v-btn color="primary" text @click="close">
                        {{ this.$t('generic.lang_save') }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <v-card-text v-if="hasMHD">
        <v-row justify="center">
          <v-dialog v-model="hasMHD" max-width="500" persistent>
            <!--if hasMHD dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form ref="hasMHD" lazy-validation onsubmit="return false">
                  <v-row>


                    <v-col class="pb-0 mb-0 pt-0 mt-0" cols="12">
                      <v-date-picker v-model="editedItem.bestBeforeDate " class="elevation-0" full-width
                                     show-current/>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12">
                      <BaseDateInput v-model="editedItem.bestBeforeDate " :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :rules="[v => !!v]"
                                    autofocus class="pb-0 mb-0"
                                    dense outlined type="date"
                                    @focus="showTouchKeyboard" @keypress.enter="close"/>
                    </v-col>

                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t('generic.lang_cancel') }}
                      </v-btn>
                      <v-btn color="primary" text @click="close">
                        {{ this.$t('generic.lang_save') }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <v-card-text v-if="chargeDue">
        <v-row justify="center">
          <v-dialog v-model="chargeDue" max-width="500" persistent>
            <!--if hasChargenNo && hasMHD dialog-->
            <v-card class="pa-3">
              <v-card-text class="">
                <v-form ref="chargeDue" lazy-validation onsubmit="return false">
                  <v-row>

                    <v-col class="pb-0 mb-0 pt-0 mt-0" cols="12">
                      <v-date-picker v-model="editedItem.bestBeforeDate " class="elevation-0" full-width
                                     show-current/>
                    </v-col>
                    <v-col class="pb-0 mb-0" cols="12">
                      <BaseDateInput v-model="editedItem.bestBeforeDate " :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :rules="[v => !!v]" class="pb-0 mb-0" dense outlined
                                    type="date" @focus="showTouchKeyboard"/>
                    </v-col>

                    <v-col class="" cols="12">
                      <v-text-field v-model="editedItem.batchNo" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :rules="[v => !!v]" dense :label="$t('erp.lang_enterChargeNo')"
                                    outlined @focus="showTouchKeyboard"
                                    @keypress.enter="close"/>
                    </v-col>

                    <v-col class="text-right pa-0 ma-0" cols="12">
                      <v-btn color="error" text @click="close">
                        {{ this.$t('generic.lang_cancel') }}
                      </v-btn>
                      <v-btn color="primary" text @click="close">
                        {{ this.$t('generic.lang_save') }}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>

      <v-dialog v-model="note_dialog" max-width="600px" scrollable>
        <v-card>
          <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="border-bottom card-header">
            {{ this.$t('generic.lang_notes') }}

            <v-spacer/>
            <v-btn absolute color="error" icon style="right: 10px" @click="close_note">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-form ref="note_form" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-textarea v-model="note_form.note" :label="this.$t('generic.lang_internalNotes')"
                              :rules="[v => !!v]"
                              outlined prepend-inner-icon="comment"/>
                </v-col>


                <v-col cols="12">
                  <v-text-field id="note_file" v-model="note_form.file" dense :label="$t('generic.lang_attachFile')" outlined
                                prepend-icon=""
                                prepend-inner-icon="attach_file"
                                type="file"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer pt-0 pb-0">
            <v-btn color="error" elevation="0" @click="close_note">
              {{ this.$t('generic.lang_cancel') }}
            </v-btn>

            <v-spacer/>

            <v-btn :disabled="save_note_loader" :loading="save_note_loader" color="primary" elevation="0"
                   @click="save_note">
              {{ this.$t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>

    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import Datatable from "@/components/datatable/Datatable";
import {Events} from "@/plugins/events";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';
import mixin from "@/mixins/KeyboardMixIns";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "ItemsControlComponent",
  components: {
    Datatable,
    quillEditor,
    BaseDateInput
  },
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      items: [],
      note_form: {
        note: "",
        file: ""
      },
      loading: false,
      loadSave: false,
      order: null,
      search: "",
      chargeDue: false,
      hasChargenNo: false,
      hasMHD: false,
      editedItem: null,
      note_dialog: false,
      save_note_loader: false
    }
  },
  mounted() {
    this.loadOrder();
  },
  watch: {
    "form.emails": function (val, prev) {
      if (val.length === prev.length) return

      this.form.emails = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[Math.floor((Math.random() * 10) + this.form.emails.length)],
          }
        }

        return v
      })
    },

  }
  ,
  methods: {
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    async save_note() {
      if (!this.$refs.note_form.validate()) return;

      if (this.note_form.file) {
        const file = document.querySelector('#note_file').files[0];
        this.note_form.file_to_upload = await this.toBase64(file);
      }
      this.save_note_loader = true;

      let dt = new FormData();
      for (let elt in this.note_form)
        dt.append(elt, this.note_form[elt]);

      dt.append('order_uuid', this.$route.params.id);

      this.axios.post(ENDPOINTS.ERP.ORDERWARE.NOTES, dt).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
          this.close_note();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: "err.message",
          color: "error"
        });
      }).finally(() => {
        this.save_note_loader = false;
      })
    },
    openNote() {
      this.note_dialog = true;
    },
    close_note() {
      this.note_dialog = false;
      this.$refs.note_form.reset();
    },
    save() {
      this.loadSave = true;
      this.axios.post(ENDPOINTS.ERP.ORDERWARE.UPDATECONTROL, {
        orderUUID: this.$route.params.id,
        positions: this.items
      }).then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_orderSuccessfullImported'),
            color: "success"
          });
          this.$router.go(-1);

        } else if (res.data.STATUS === 'FAILED') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loadSave = false;
      })
    },
    scan(item) {

      this.$nextTick(() => {
        this.chargeDue = (item.chargeNoRequired && item.mhdRequired);
        this.hasChargenNo = (item.chargeNoRequired && !item.mhdRequired);
        this.hasMHD = (!item.chargeNoRequired && item.mhdRequired);
        this.editedItem = item;
      })
    },
    close() {
      this.hasChargenNo = this.hasMHD = this.chargeDue = false
      this.editedItem = null;
    },
    getColor(currentQTY, minQTY, standQTY) {
      if (currentQTY <= minQTY)
        return "error";
      else if (currentQTY >= minQTY && currentQTY < standQTY)
        return "warning";
      else return "success";

    }
    ,
    loadOrder() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.ORDERWARE.DETAILS, {
        orderUUID: this.$route.params.id
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.items = [];
          this.items = res.data.positions;
          this.order = res.data.order;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
  ,
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('erp.lang_ware_create_id'),
          value: 'ean'
        },
        {
          text: this.$t('erp.lang_orderNo'),
          value: 'number'
        },
        {
          text: this.$t('erp.lang_itemname'),
          value: 'wareName',
          sortable: false
        },
        {
          text: this.$t('erp.lang_actualStock'),
          value: 'currentQTY',
          align: "center"
        },
        {
          text: this.$t('erp.lang_warecreate_minqty'),
          value: 'minBestand'
        },
        {
          text: this.$t('erp.lang_warecreate_sollQTY'),
          value: 'sollBestand'
        },
        {
          text: this.$t('erp.lang_receivedItems'),
          value: 'orderedQTY',
          align: "left",
          width: 150
        },
        {
          text: this.$t('erp.lang_packagesReceived'),
          value: 'packagesRecieved',
          align: "center"
        },
        {
          text: this.$t('erp.lang_chargeNoDueDate'),
          value: 'actions',
          align: "center"
        }
      ];
    }
    ,
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}
</style>

