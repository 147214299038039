<template>
  <div>
    <page-title :icon=icon heading="Pre-Order Goods" show-previous-button
                subheading="Pre-Order Goods"></page-title>
    <div class="app-main__inner">
      <items-control-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../../Layout/Components/PageTitle.vue";
import ItemsControlComponent from "@/components/erp/storage/goodreceipt/preorder/ItemsControlComponent";

export default {
  components: {
    ItemsControlComponent,
    PageTitle
  },

  data: () => ({
    icon: 'pe-7s-box1 icon-gradient bg-tempting-azure',
  })
}
</script>